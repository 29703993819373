"use strict";
/**
 * The job list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular
  .module("cpformplastApp.jobs")
  .controller(
    "jobPlanningController",
    function (
      $scope,
      $state,
      DataManager,
      Notification,
      $modal,
      Modal,
      SubjobSchedule,
      Auth,
      Subjob,
      Util
    ) {
      const PreviewModeOnlyRoles = ["mold_dept"];

      $scope.tableStructure = [
        {
          key: "job",
          title: "# JOB",
          width: 100,
          style: "padding: 9px 0px; padding-left: 10px;",
        },
        {
          key: "mold",
          title: "# MOULE",
          width: 90,
          style: "font-weight: bold; padding: 9px 0px;",
        },
        {
          key: "requiresOvertime",
          title: "OVERTIME",
          width: 90,
          style: "padding: 0px;",
        },

        {
          key: "client",
          title: "CLIENT",
          width: 300,
          style: "padding: 9px 0px; padding-right: 10px;",
        },
        {
          key: "item",
          title: "ITEM",
          width: 400,
          style: "",
        },
        {
          key: "ups",
          title: "UPS",
          width: 50,
          style: "",
        },
        {
          key: "material",
          title: "MATÉRIEL",
          width: 200,
          style: "",
        },
        {
          key: "materialReady",
          title: "STATUT MATÉRIEL",
          width: 150,
          style: "padding: 0px;",
        },
        {
          key: "remainingQuantity",
          title: "QTÉ.",
          width: 150,
          style: "font-weight: bold; text-align: center;",
        },
        {
          key: "deliveryDate",
          title: "LIVR.",
          width: 110,
          style: "",
        },
        {
          key: "skid",
          title: "SKID",
          width: 70,
          style: "text-align:center;",
        },
        {
          key: "sheetsPerHour",
          title: "FLS/HR",
          width: 70,
          style: "text-align:center;",
        },
        {
          key: "hoursAmount",
          title: "# HR",
          width: 80,
          style:
            "font-weight: bold; border-left: 1px solid #ddd; border-right: 1px solid #ddd;",
        },
        {
          key: "start",
          title: "DÉBUT",
          width: 110,
          style: "",
        },
        {
          key: "end",
          title: "FIN",
          width: 110,
          style: "border-left: 1px solid #ddd; border-right: 1px solid #ddd;",
        },
        {
          key: "salesman",
          title: "VENDEUR",
          width: 130,
          style: "text-align:center;",
        },
        {
          key: "po",
          title: "#PO",
          width: 130,
          style: "text-align:center",
        },
        {
          key: "poDate",
          title: "Date #PO",
          width: 110,
          style: "text-align:center",
        },
        {
          key: "moldOut",
          title: "MOULE SORTIE",
          width: 80,
          style: "text-align:center;",
        },
        {
          key: "unitsPerDay",
          title: "MCX/JR",
          width: 80,
          style: "text-align:center",
        },
        {
          key: "poundsNeeded",
          title: "NEEDS QTÉ LBS",
          width: 90,
          style: "text-align:center",
        },
        {
          key: "poundsPerWeek",
          title: "LBS/SEMAINE (40h)",
          width: 120,
          style: "text-align:center",
        },
      ];

      $scope.machines = [];
      $scope.searchString = "";
      $scope.machineType = "manual";
      $scope.newSubjobsCount = 0;
      $scope.isPreviewMode = false;
      $scope.isOrganizingJobs = false;

      /**
       * Initialisation function
       */

      $scope.init = function () {
        $scope.setIsPreviewMode();
        $scope.fetchMachines();
        $scope.fetchNewSubjobsCount();
        $scope.fetchSalesmen();
        $scope.deliveryDatePickerOpen = false;

        $(window).resize(function () {
          $scope.adjustPageSize();
        });

        $scope.syncHeaderScroll();
      };

      $scope.setIsPreviewMode = function () {
        Auth.getCurrentUser(function (user, err) {
          $scope.isPreviewMode =
            PreviewModeOnlyRoles.findIndex((x) => x === user.role) !== -1;
        });
      };

      $scope.fetchMachines = function () {
        DataManager.fetchSubjobSchedules()
          .then((machines) => {
            $scope.machines = machines.sort((a, b) => a.number - b.number);
            $scope.adjustPageSize();
          })
          .catch((err) => {
            console.log(err);
            Notification.error(
              "Un problème est survenu lors du chargement des cédules"
            );
          });
      };

      $scope.organizeJobs = function () {
        $scope.isOrganizingJobs = true;
        DataManager.fetchSubjobSchedules("machine-organized")
          .then((machines) => {
            $scope.machines = machines.sort((a, b) => a.number - b.number);
            $scope.adjustPageSize();
            $scope.isOrganizingJobs = false;
          })
          .catch((err) => {
            console.log(err);
            Notification.error(
              "Un problème est survenu lors du chargement des cédules"
            );
            $scope.isOrganizingJobs = false;
          });
      };

      $scope.fetchNewSubjobsCount = () => {
        DataManager.fetchNewSubjobsCount().then((count) => {
          $scope.newSubjobsCount = count;
        });
      };

      $scope.fetchSalesmen = () => {
        DataManager.fetchSalesmen().then((salesmen) => {
          $scope.salesmen = salesmen;
        });
      };

      /**
       * Search and utilities
       */

      $scope.salesmen = [];
      $scope.salesmanToPrint = "None";

      $scope.printContent = function () {
        let title =
          "Planification des jobs (" + Util.getDateTimeString(new Date()) + ")";

        if ($scope.salesmanToPrint === "All") {
          DataManager.downloadJobSchedulePdf($scope.machineType);
        } else if ($scope.salesmanToPrint === "AllSmall") {
          // print for all salesmen, but in the format used for a specific one
          DataManager.downloadSalesmenSchedulePdf(
            $scope.salesmen.map((salesman) => salesman._id),
            $scope.machineType
          );
        } else {
          // only print for a specific salesman
          DataManager.downloadSalesmenSchedulePdf(
            [$scope.salesmanToPrint],
            $scope.machineType
          ).catch((err) => {
            if (err.status !== 200) {
              Notification.error(
                "Un problème est survenu lors du téléchargement du PDF"
              );
            }
          });
        }

        // Reset selection
        $scope.salesmanToPrint = "None";
        const select = document.getElementById("print-select");
        select.selectedIndex = 0;
      };

      /**
       * Subjob schedules management function
       */

      $scope.didClickNewJobs = () => $state.go("jobs/job-planning/new-jobs");

      $scope.editSubjobSchedule = function (subjobSchedule) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl:
            "../../components/modal/subjob-schedule/subjob-schedule-edit.html",
          controller: "SubjobScheduleEdit",
          resolve: {
            subjobSchedule: function () {
              return subjobSchedule;
            },
            editable: function () {
              return true;
            },
          },
        });
        modalInstance.result.then(null, function () {
          $scope.init();
        });
      };

      $scope.addNoteToSubjob = function (subjob) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl:
            "../../components/modal/subjob-schedule/job-notes-modal.html",
          controller: "JobNotesModal",
          scope: $scope,
          resolve: {
            subjob: function () {
              return subjob;
            },
          },
        });
        modalInstance.result.then(null, function () {
          $scope.init();
        });
      };

      $scope.editProducedQuantity = function (subjobSchedule) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl:
            "../../components/modal/subjob-schedule/production-report-modal.html",
          controller: "ProductionReportModal",
          resolve: {
            subjobSchedule: function () {
              return subjobSchedule;
            },
          },
        });
        modalInstance.result.then(null, function () {
          $scope.init();
        });
      };

      $scope.editItemEfficiency = function (schedule) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl:
            "../../components/modal/subjob-schedule/item-efficiency-modal.html",
          controller: "ItemEfficiencyModal",
          resolve: {
            item: function () {
              return schedule.subjob.job.item;
            },
            subjobSchedule: () => {
              return schedule;
            },
          },
        });
        modalInstance.result.then(null, function () {
          $scope.init();
        });
      };

      $scope.editMachinesProduction = function () {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl:
            "../../components/modal/subjob-schedule/machines-production.html",
          controller: "MachinesProductionModal",
          scope: $scope,
          resolve: {
            machines: function () {
              return $scope.machines;
            },
          },
        });
        modalInstance.result.then(null, function () {
          $scope.init();
        });
      };

      $scope.updateSubjobSchedule = function (subjobSchedule) {
        DataManager.updateSubjobSchedule(subjobSchedule)
          .then((machines) => {
            $scope.machines = machines;
          })
          .catch((err) => {
            console.log(err);
            Notification.error("Un problème est survenu");
          });
      };

      $scope.updateJob = function (job) {
        DataManager.updateJob(job)
          .catch((err) => {
            console.log(err);
            Notification.error("Un problème est survenu");
          });
      };

      $scope.deleteMaterialStatus = function (subjobSchedule) {
        subjobSchedule.subjob.job.materialDeliveryDate = null;
        subjobSchedule.materialReady = false;
        
        $scope.updateJob(subjobSchedule.subjob.job);
        $scope.updateSubjobSchedule(subjobSchedule);
      };

      $scope.machineDropCallback = function (
        index,
        item,
        external,
        type,
        machine
      ) {
        const subjobSchedule = new SubjobSchedule(item);
        $scope.handleScheduleDrop(index, subjobSchedule, machine);
        return subjobSchedule;
      };

      $scope.handleScheduleDrop = function (index, subjobSchedule, machine) {
        subjobSchedule.machineId = machine.id;

        if (index > 0) {
          const previousSubjobSchedule = machine.subjobSchedules[index - 1];
          subjobSchedule.startDate = previousSubjobSchedule.endDate;
        } else if (machine.subjobSchedules.length > 0) {
          subjobSchedule.startDate = machine.subjobSchedules[0].startDate;
          subjobSchedule.startDate.setHours(
            subjobSchedule.startDate.getHours() - 1
          );
        }
        DataManager.updateSubjobSchedule(subjobSchedule)
          .then((machines) => {
            $scope.machines = machines;
          })
          .catch((err) => {
            console.log(err);
            Notification.error("Un problème est survenu");
          });
      };

      $scope.didClickDelete = function (subjobSchedule) {
        const modalFunction = Modal.confirm.delete((res) => {
          DataManager.deleteSubjobSchedule(subjobSchedule)
            .then((machines) => {
              $scope.machines = machines;
            })
            .catch((err) => {
              console.log(err);
              Notification.error("Un problème est survenu");
            });
        });
        return modalFunction(
          `la cédule ${subjobSchedule.subjob.title}`,
          "retirer"
        );
      };

      $scope.isDateAffectedBySchedule = function (
        machineSchedule,
        subJobSchedule
      ) {
        //we check if a machine schedule change is made between  the start date of the subjob schedule
        const schedule = machineSchedule.findIndex(
          (x) =>
            x.start &&
            moment(x.start).isBetween(
              subJobSchedule.startDate,
              subJobSchedule.endDate,
              "day",
              "[]"
            )
        );

        return schedule !== -1 && machineSchedule
          ? !machineSchedule[schedule].isDefaultSchedule
          : false;
      };

      $scope.completeSubjobSchedule = (schedule) => {
        const modal = Modal.confirm.endCycle((res) => {
          DataManager.completeSubjobSchedule(schedule)
            .then((machines) => {
              $scope.machines = machines;
              Notification.success("Cycle complété");
            })
            .catch((err) => {
              console.log(err);
              Notification.error("Un problème est survenu");
            });
        });

        modal(schedule.subjob.title);
      };

      /**
       * DOM Management methods
       */

      $scope.hasCustomWaitTime = function (subJobSchedules) {
        const LONG_WAIT = 20; // default value in the BE for LW
        const SHORT_WAIT = 4; // default value in the FE for SW
        const idx = subJobSchedules.findIndex(
          (x) =>
            x.waitTimeBeforeNextSchedule &&
            x.waitTimeBeforeNextSchedule !== SHORT_WAIT &&
            x.waitTimeBeforeNextSchedule !== LONG_WAIT
        );
        return idx === -1 ? "#4a90e2" : "#de0404";
      };

      $scope.getMachinePannelHeight = function (machine) {
        if (machine.subjobSchedules.length === 0) {
          return 42;
        }
        var columnCount = 0;
        var lineCount = 0;
        for (const subjobSchedule of machine.subjobSchedules) {
          columnCount += 1;
          lineCount += 1;
          if (subjobSchedule.subjob.job.secondItem) columnCount += 1;
          if (subjobSchedule.subjob.job.thirdItem) columnCount += 1;
        }
        return columnCount * 40 + lineCount * 1 + 12;
      };

      $scope.syncHeaderScroll = function () {
        $(".schedule-container").on("scroll", function () {
          $(".header-container").scrollLeft($(this).scrollLeft());
        });
      };

      $scope.adjustPageSize = function () {
        const pageHeight = $(window).height();
        const topnavHeight = $("#topnav").height();
        const breadcrumbHeight = $(".breadcrumb").height();
        const searchRowHeight = $(".search-row").height();
        const headerHeight = $(".planning-header").height();

        const leftMenuHeight =
          pageHeight - topnavHeight - breadcrumbHeight - searchRowHeight - 60;
        $(".job-planning-left-menu").height(leftMenuHeight);

        if (!$scope.showNewJobs) {
          $(".newjob-container").hide();
        } else {
          $(".newjob-container").show();
        }
      };

      $scope.selectMachine = function (machine) {
        $(".machine-list").animate(
          {
            scrollTop:
              $(".machine-list").scrollTop() +
              $("#machine-container-" + machine.id).position().top -
              80,
          },
          700
        );
      };

      /**
       * Formating
       */

      $scope.formatThousands = function (number) {
        return Util.formatThousands(number);
      };

      $scope.formatDate = function (date) {
        return Util.getDateString(date);
      };

      $scope.downloadJobCreationPdf = function (subjob) {
        DataManager.downloadJobCreationPdf(subjob);
      };

      $scope.init();

      $scope.formatNumber = function (number) {
        return number < 10 ? "0" + number : number;
      };
    }
  );
